import React from "react"
import Helmet from "react-helmet"

import GlobalCSS from 'src/components/styled/GlobalCSS'
import { Edges } from "src/components/styled/Edges"
import { Space } from "src/components/styled/Space"
import Typography from "src/components/styled/Typography"

import Blob from "src/components/animated/Blob"

const Layout = ({ children }) => {
  return (
    <Edges>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Typography />
      <GlobalCSS />
      <Blob />
      <Space size={2} />
      {children}
    </Edges>
  )
}

export default Layout
