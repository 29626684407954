import styled from "@emotion/styled"

export const Title = styled.h4`
  color: black;
  margin-bottom: .5rem;
  margin-top: .5rem;
`

export const TitleArea = styled.div``

export const ImageTitle = styled.div`
  display: flex;
  align-items: center;
`

export const Card = styled.a`
  background: rgba(255,255,255, 0.7);
  box-shadow: 0px 0px 2rem 1.5rem rgba(0, 0, 0, 0.0196078431372549);
  padding: 2rem;
  border-radius: .5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black;
  text-decoration: none;
  margin-bottom: 1rem;
  margin-top: .5rem;
`

export const CardParagraph = styled.p`
  font-size: .75rem;
  color: black;
  max-width: 600px;
`

export const Stats = styled.section`
  display: flex;
  font-size: .75rem;
`

export const Stat = styled.span`
  display: flex;
  align-items: center;
  margin-right: 1rem;

  img {
    margin-bottom: 0;
    margin-right: .25rem;
  }
`