import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Image from 'gatsby-image'

import parse from 'html-react-parser'

import { 
  Title,
  TitleArea,
  ImageTitle,
  Card,
  CardParagraph,
  Stats,
  Stat,
} from 'src/components/styled/Card'

import starIcon from "src/icons/star.svg"
import forkIcon from "src/icons/fork.svg"




const GithubProject = project => {
  const { description } = project?.frontmatter || {}
  const { repository } = project?.fields || {}

  const image = project?.frontmatter?.image?.childImageSharp?.fixed

  return (
  <Card href={repository.url}
        target="_blank"
        rel="noopener noreferrer" key={project.id}>
    <div>
      <ImageTitle>
        {!!image && !project.hideImages && (
          <Image 
            style={{ 
              marginBottom: project?.frontmatter?.imageMarginBottom,
              marginRight: '1rem',
              boxShadow: project?.frontmatter?.imageDropShadow 
                ? `0px 0px 1rem .5rem rgba(0, 0, 0, 0.05)` 
                : null
            }} 
            fixed={image} 
          />
        )}
        <TitleArea>
          <Title>{repository.name}</Title>
          {!project.hideDescription && (
            <CardParagraph>{parse(project.frontmatter.description)}</CardParagraph>
          )}
          {!!image && (
            <Stats>
              <Stat>
                <img src={starIcon} alt="stars" />
                <span>{repository.stargazers.totalCount}</span>
              </Stat>
              <Stat>
                <img src={forkIcon} alt="forks" />
                <span>{repository.forkCount}</span>
              </Stat>
            </Stats>
          )}
        </TitleArea>
      </ImageTitle>
      {!image && (
        <Stats>
          <Stat>
            <img src={starIcon} alt="stars" />
            <span>{repository.stargazers.totalCount}</span>
          </Stat>
          <Stat>
            <img src={forkIcon} alt="forks" />
            <span>{repository.forkCount}</span>
          </Stat>
        </Stats>
      )}
    </div>
  </Card>
)}

const GithubProjects = ({ byArrayOfTitles, excludeByArrayOfTitles, ...props }) => {
  const { githubProjects } = useStaticQuery(graphql`
    {
      githubProjects: allMarkdownRemark(
        sort: {
          fields: fields___repository___stargazers___totalCount
          order: DESC
        }
        filter: { frontmatter: { template: { eq: "github-project" } } }
      ) {
        nodes {
          frontmatter {
            description
            imageMarginBottom
            imageDropShadow
            image {
            childImageSharp {
              fixed(width: 150) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          }
          fields {
            repository {
              name
              url
              forkCount
              stargazers {
                totalCount
              }
            }
          }
        }
      }
    }
  `)

  return githubProjects.nodes.length
    ? githubProjects.nodes
      .filter(
        ({ fields: {repository} }) => byArrayOfTitles 
          ? byArrayOfTitles.includes(repository.name) 
          : true 
      )
      .filter(
        ({ fields: {repository} }) => excludeByArrayOfTitles 
          ? !excludeByArrayOfTitles.includes(repository.name) 
          : true 
      )
      .map(project => <GithubProject {...project} {...props} />)
    : null
}
export default GithubProjects
