import React from "react"

import tyjs from "tyjs"
import { Global, css } from "@emotion/core"

const typeConfig = {
  bodyFontFamily: ["helvetica", "sans-serif"],
  headerFontFamily: ["helvetica", "sans-serif"],
  bodyWeight: 100,
  headerWeight: 600,
  baseFontSize: "19px",
  baseLineHeight: 1.6,
  scaleRatio: 1.9,
  blockMarginBottom: 1,
  includeNormalize: true,
  breakpoints: {
    "@media screen and (min-width: 700px)": {
      baseFontSize: "24px",
      scaleRatio: 2.4,
    },
    "@media screen and (min-width: 1500px)": {
      baseFontSize: "25px",
      scaleRatio: 3,
    },
  },
}

const typography = new tyjs(typeConfig)

const Typography = () => (
  <Global
    styles={css`
      ${typography.toString()}
    `}
  />
)

export default Typography
