import React from "react"
import { Global, css } from "@emotion/core"

const GlobalCSS = () => (
    <Global
        styles={css`
            .make-emojis-large {
                font-size: 2em;
            }
            .make-emojis-small-and-raised {
                display: inline-block;
                font-size: 0.5em;
                transform: translateY(-50%);
                margin-right: 0.5em;
                margin-left: -0.5em;
            }

            .sidenote {
                font-size: 0.75em;
                display: inline-block;
                transform: translateY(-10%);
                font-weight: 200;
                font-style: italic;

                @media screen and (max-width: 700px) {
                    display: inline;
                }
            }

            a,
            a:visited,
            a:link,
            a:active {
                color: blue;
            }

            ul {
                padding-top: 1rem;
            }
            li {
                @media screen and (min-width: 700px) {
                    font-size: 0.8rem;
                }
                margin-bottom: 0.5rem;
            }
        `}
    />
)

export default GlobalCSS
