import React from "react"

import { Twemoji as Emoji } from "react-emoji-render"

import Layout from "src/components/Layout"

import Paragraph from "src/components/styled/Paragraph"
import MonoTitle from "src/components/styled/MonoTitle"

import GithubProjects from "src/components/GithubProjects"
import ContactInfo from "src/components/ContactInfo"

import { Space } from "src/components/styled/Space"

const IndexPage = () => {
    return (
        <Layout>
            <Space size={2} />
            <h1>
                Hello fellow humans! <Emoji svg text=":wave: :smile:" />
            </h1>
            <MonoTitle>(Or whomever may be reading this!)</MonoTitle>
            <h2>
                My name is Tyler Barnes <Emoji svg text=":nerd_face:" />
            </h2>
            <MonoTitle>
                Nice to meet you.{" "}
                <Emoji
                    svg
                    text=":handshake:"
                    onlyEmojiClassName="make-emojis-large"
                />
            </MonoTitle>
            <Paragraph>
                I'm a Software Engineer working on the{" "}
                <a href="https://www.gatsbyjs.com">Gatsby</a> Inkteam.
                <br />
                My current focus is on making headless WordPress with Gatsby the
                easiest and best way to build a WordPress powered site.
            </Paragraph>

            <Space size={1} />

            <h2>My projects</h2>

            <GithubProjects />

            <Space size={1} />

            <h2>Blog posts</h2>
            <a href="https://www.gatsbyjs.org/blog/2018-12-04-per-link-gatsby-page-transitions-with-transitionlink/">
                Per-Link transitions with gatsby-plugin-transition-link
            </a>

            <Space size={4} />

            <h2>Contact Info</h2>
            <ContactInfo />
            <Space size={6} />
        </Layout>
    )
}

export default IndexPage
