import React from "react"
import { Space } from "src/components/styled/Space"

import { Twemoji as Emoji } from "react-emoji-render"

const ContactInfo = () => {
  return (
    <div>
      I live in Vancouver, BC, Canada.
      <Space size={1} />
      DM me on twitter if you want to talk! <a href='https://twitter.com/tylbar'>@tylbar</a>
      <Space size={1} />
      <Emoji svg text=":v: :smile:" />
    </div>
  )
}

export default ContactInfo
