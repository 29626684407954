import React, { useRef, useState, useEffect } from "react"
import PropTypes from "prop-types"

import styled from "@emotion/styled"

import useBlob from "./useBlob"

const StyledCanvas = styled.canvas`
  position: fixed;
  top: ${({top}) => top ? top : 0};
  left: ${({left}) => left ? left : 0};
  width: 100%;
  height: 100vh;
  z-index: -1;
`

const Blob = props => {
  const canvasRef = useRef()

  const [state, setState] = useState({ loaded: false })

  useEffect(() => {
    setState({ loaded: true })
  }, [canvasRef.current])

  useBlob({
    canvasElement: canvasRef.current,
    loaded: state.loaded,
    color: props.color || "#f7f7f7",
    radius: props.radius || 400
  })

  return <StyledCanvas {...props} ref={canvasRef} />
}

export default Blob
