import styled from '@emotion/styled'

export const Edges = styled.section`
    width: 700px;
    max-width: 80%;
    margin: 0 auto;

    @media screen and (min-width: 1500px) {
        width: 800px;
    }
`